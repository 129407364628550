﻿import ComponentLoader from '@SharedScripts/ComponentLoader';

class ResizeIframeEntry extends ComponentLoader {
  constructor(element: HTMLElement) {
    super(element);
    this.init();
  }

  init() {
    const iframe = this.settings.rootElement.querySelector('iframe');

    const resize = () => {
      iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    };

    try {
      if (iframe?.contentWindow?.document?.readyState) {
        const iframePolling = setInterval(() => {
          if (iframe.contentWindow.document.readyState === 'complete') {
            resize();
            clearInterval(iframePolling);
          }
        }, 100);
      }
    } catch (ignore) {}
  }
}

export default ResizeIframeEntry;